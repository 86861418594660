<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>
                    ERROR!
                </h1>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'ErrorPage'
}
</script>