<template>
    <div class="productItem">
      
        <router-link :to="'/tienda/productos/'+ product.url">
            <v-img class="product-feed-cover" eager  aspect-ratio="1" :contain="true" :src="'https://rawkv3.actstudio.xyz/storage/uploads/'+product.cover.path"></v-img>
            <v-img class="product-feed-hover" eager  aspect-ratio="1" :contain="true" :src="'https://rawkv3.actstudio.xyz/storage/uploads/'+product.coverHover.path"></v-img>
            
        </router-link>
        <v-row>
            <v-col cols="12" align="center">
                <p>{{product.name}}</p>
                <p v-if="selectedSize">${{selectedSize.price}} </p>
            </v-col>
            <v-col cols="12" align="center">
                <div class="add-cart-container">
                    <template v-if="availableSizes.length > 0">
                        <div class="add-cart-count" v-if="!product.showSize">
                            <template v-if="sizes.length > 0">
                                <v-select :items="availableSizes" item-text="size"  item-value="_id"
                                    :value="selectedSize._id" 
                                    v-model="sel"
                                ></v-select>
                            </template>
                        </div> 
                        
                        <hr v-if="!product.showSize">
                        <v-btn class="add-to-cart" text :ripple="false" plain @click="addToCart()">
                            Agregar a la cesta
                        </v-btn>
                    </template>   
                    <template v-if="availableSizes.length === 0">
                        <router-link :to="'/tienda/productos/'+ product.url">
                            ver producto
                        </router-link>
                    </template>                         
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'ProductItem',
    data(){
        return{
            sel: 0,
            selectedSize: {
                _id: null,
                size: ''
            }
        }
    },
    props: {
        product: {
            type: Object
        },
        sizes: {
            type: Array,
            price: ''
        }
    },
    mounted: function(){
        setTimeout(() => {
            let availSizes =  this.sizes.filter(elem => elem.inventory > 0);
            this.selectedSize = availSizes[0];
            if(this.selectedSize) this.sel = this.selectedSize._id;
        }, 1000);
    },
    methods: {

        addToCart: function(){
            let productToAdd = this.sizes.filter(elem => elem._id === this.sel)[0];
            let cartItem = {
                _id: productToAdd._id,
                quantity: 1,
                inventory: productToAdd.inventory,
                size: productToAdd.size,
                price: productToAdd.price,
                image: this.product.cover,
                name: this.product.name,
                subCategory: this.product.SubCategory.name
            }

            this.$root.$emit('addProductToCart', cartItem );
        }

    },
    computed: {
        availableSizes: function(){
            return this.sizes.filter(elem => elem.inventory >0);
        }
    },
    watch: {
        $route: function(){
            setTimeout(() => {
                this.selectedSize = this.sizes[0];
                this.sel = this.selectedSize._id;
            }, 1000);
        }
    }
}
</script>