<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay >
        <template v-slot:activator="{ on, attrs }">
            <v-btn id="guide-button" plain light v-bind="attrs" v-on="on" small>
                VER GUÍA
            </v-btn>
        </template>
        <v-card>
            <v-container class="guide-container">
                <v-row>
                    <v-btn icon plain @click="dialog = false">
                        <v-icon x-large>mdi-close</v-icon>
                    </v-btn>
                    <v-col cols="12" v-if="guideDesk">
                        <v-img class="guide-image-desk"  aspect-ratio="1" :contain="true"  :src="'https://rawkv3.actstudio.xyz/storage/uploads'+guideDesk.path"></v-img>
                        <v-img class="guide-image-mobile"  aspect-ratio="1" :contain="true"  :src="'https://rawkv3.actstudio.xyz/storage/uploads'+guideMobile.path"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'GuideModal',
    data: () => {
        return {
            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,
        }
    },
    props: {
        guideDesk: {
            type: Object
        },
        guideMobile: {
            type: Object
        }
    }
}
</script>