<template>
    <div class="product">
        <ErrorPage v-if="showErrorPage" />
        <v-container v-if="!showErrorPage">
            <v-row>
                <v-col class="product-info"  cols="12"  md="6">
                     <h1> 
                        {{product.SubCategory.name}} <br>
                        {{product.name}}
                    </h1> 
                    <h2>{{product.subtitle}}</h2> 
                    <div class="colors-container">
                        <a v-if="product.color" >
                            <img :src="'https://rawkv3.actstudio.xyz/storage/uploads'+product.color.path" alt="Color" />
                        </a>
                        <a v-if="product.color1" >
                            <img :src="'https://rawkv3.actstudio.xyz/storage/uploads'+product.color1.color.path" alt="Color 2" />
                        </a>
                        <a v-if="product.color2" >
                            <img :src="'https://rawkv3.actstudio.xyz/storage/uploads'+product.color2.color.path" alt="Color 3" />
                        </a>
                    </div>
                    <div id="size-container-desk">
                        <div class="select-size-title">
                            <p>SELECCIONA TU TALLA </p>
                            <GuideModal v-if="this.product.guideDesk" v-bind:guideDesk="this.product.guideDesk" v-bind:guideMobile="this.product.guideMobile" />
                        </div>
                        
                        <v-row>
                            <v-col class="size-container" md="7">
                                <template v-for="(item,i) in productInfo">
                                    <template v-if="item.inventory != '0'">
                                        <v-btn :key="i" 
                                            @click="selectedSize = item" 
                                            v-bind:class="{'selected' : selectedSize._id == item._id}"
                                            text :ripple="false" plain
                                        >
                                            {{item.size}}
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <v-btn :key="i" class="noInventory" text :ripple="false" plain>
                                            {{item.size}}
                                        </v-btn>
                                    </template>
                                </template>
                            </v-col>
                            <v-col md="12" class="text-right">
                                
                            </v-col>
                        </v-row>    
                    </div>
                    <div id="price-container-desk" class="price-container">
                        <p>COSTO</p>
                        <div class="price">
                            <p class="old-price" v-if="selectedSize.crossedPrice > 0">${{selectedSize.crossedPrice}} </p>     
                            <p class="current-price" v-if="selectedSize.price">${{selectedSize.price}} </p>  
                        </div>
                    </div>
                    <div id="add-cart-desk-container" class="add-cart-container">
                        <template v-if="availableSizes.length > 0">
                            <div class="add-cart-count">
                            <span>{{quantity}}</span>
                            <div>
                                <v-btn icon text :ripple="false" small>
                                    <v-icon @click="add()"> mdi-chevron-up</v-icon>
                                </v-btn>
                                <v-btn icon text :ripple="false" small>
                                    <v-icon @click="remove()"> mdi-chevron-down</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <hr>
                        <v-btn @click="addToCart()" class="add-to-cart" text :ripple="false" plain>
                            Agregar a la cesta
                        </v-btn>
                        </template>
                        <template v-if="availableSizes.length == 0">
                            <p>
                                Out of Stock
                            </p>
                        </template>
                        
                    </div>
                </v-col>
                <v-col cols="12"  md="6" >
                    <v-carousel class="carousel-container" :show-arrows="false" height="auto" light hide-delimiter-background>
                        <v-carousel-item v-for="(item,i) in product.images" :key="i" >
                            <v-img class="product-image"  aspect-ratio="1" :contain="true"  :src="'https://rawkv3.actstudio.xyz/storage/uploads'+item.path"></v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
            <v-row id="price-mobile">
                <v-col align="center" class="price-container">
                    <p>COSTO</p>
                    <div class="price">
                        <p class="old-price" v-if="selectedSize.crossedPrice">${{selectedSize.crossedPrice}} </p>     
                        <p class="current-price" v-if="selectedSize.price">${{selectedSize.price}} </p>  
                    </div>
                </v-col>
            </v-row>

            <v-row class="container-2 mb-7">
                <MarqueeText :repeat="20">
                    <span class="product-category">{{product.SubCategory.name}}</span>
                    <span class="product-name">{{product.name}}</span>
                    <span class="product-subtitle">{{product.subtitle}}</span>
                </MarqueeText>
                <v-col cols="12" md="6" > 
                    <v-carousel interval="8000" :cycle="true" :show-arrows="false" height="580px" light hide-delimiters>
                        <v-carousel-item v-for="(item,i) in product.imagenSecondary" :key="i" >
                            <v-img height="550px" aspect-ratio="1" :contain="true"  :src="'https://rawkv3.actstudio.xyz/storage/uploads'+item.path"></v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col cols="12"  md="6" class="description-container">
                    <div class="description" v-html="product.description"></div>
                </v-col>
            </v-row>
            <v-row id="related-products-desk" class="mt-10 mb-10">
                <v-col cols="12" align="center">
                    <hr >
                    <h2 >
                        OTROS PRODUCTOS DE NUESTRA COLECCIÓN
                    </h2>
                </v-col>
                <v-col cols="4" v-if="this.product.relatedProduct1">
                    <ProductItem v-bind:product="this.product.relatedProduct1" v-bind:sizes="this.relatedProductSizes1" />
                </v-col>            
                <v-col cols="4" v-if="this.product.relatedProduct2">
                    <ProductItem v-bind:product="this.product.relatedProduct2" v-bind:sizes="this.relatedProductSizes2" />
                </v-col>   
                <v-col cols="4" v-if="this.product.relatedProduct3">
                    <ProductItem v-bind:product="this.product.relatedProduct3" v-bind:sizes="this.relatedProductSizes3" />
                </v-col>  
            </v-row>

            <!-- button expands mobile sheet -->
            <v-btn id="add-cart-mobile" class="add-to-cart" fixed bottom left @click="sheet = !sheet">
                Agregar a la cesta
            </v-btn>
            <v-bottom-sheet v-model="sheet">
                <v-sheet id="sheet-mobile"  height="300px" >
                    <v-container>
                        <v-row>
                            <v-col cols="12" align="right" class="pb-0">
                                <v-btn class="close-x" text plain  @click="sheet = !sheet">
                                    <v-icon  x-large>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" align="center" class="pt-0">
                                <GuideModal v-if="this.product.guideDesk"  v-bind:guideDesk="this.product.guideDesk" v-bind:guideMobile="this.product.guideMobile"/>
                            </v-col>
                            <v-col cols="12" class="size-container pt-3">
                                <template v-for="(item,i) in productInfo">
                                    <template v-if="item.inventory != '0'">
                                        <v-btn :key="i" 
                                            @click="selectedSize = item" 
                                            v-bind:class="{'selected' : selectedSize._id == item._id}"
                                            text :ripple="false" plain
                                        >
                                            {{item.size}}
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <v-btn :key="i" class="noInventory" text :ripple="false" plain>
                                            {{item.size}}
                                        </v-btn>
                                    </template>
                                </template>
                            </v-col> 
                            <v-col cols="12" class="pt-6" v-if="availableSizes.length > 0">
                                <div class="add-cart-count">
                                    <span>{{quantity}}</span>
                                    <div>
                                        <v-btn icon text :ripple="false" large>
                                            <v-icon @click="add()"> mdi-chevron-up</v-icon>
                                        </v-btn>
                                        <v-btn icon text :ripple="false" large>
                                            <v-icon @click="remove()"> mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" id="add-cart-modal" >
                                <v-btn @click="addToCart()"  text plain :ripple="false" v-if="availableSizes.length > 0">
                                    Agregar a la cesta
                                </v-btn>
                                <p class="outOfStock" v-if="availableSizes.length == 0">
                                    Out of Stock
                                </p>
                            </v-col>
                        </v-row>
                    </v-container> 
                </v-sheet>
            </v-bottom-sheet>
        </v-container>
    </div>    
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';
import GuideModal from '../components/GuideModal.vue';
import ProductItem from '../components/ProductItem.vue';
import ErrorPage from '../components/ErrorPage.vue';

export default {
    data(){
        return {
            sheet: false,
            showErrorPage: false,
            product: {
              SubCategory:{
                  name: ''
              },
              name: '',
              subtitle: '',
            },
            productInfo: [],
            quantity: 1,
            selectedSize: {
                _id: null,
                crossedPrice: ''
            },
            relatedProductSizes1: [],
            relatedProductSizes2: [],
            relatedProductSizes3: [],
        }
    },
    components: {
        MarqueeText,
        GuideModal,
        ProductItem,
        ErrorPage
    },
    beforeMount: function (){
        document.getElementById('footer').classList.add('inProduct');

        fetch('https://rawkv3.actstudio.xyz/api/content/items/Productos?populate=2&filter={url:"'+this.$route.params.productUrl+'"}', {
            method: 'GET'
        })
        .then(res => res.json())
        .then(currentProduct => this.product = currentProduct[0]) 
        .then(() => {
            //fetch for the size and inventory info
            if(this.product){
                fetch('https://rawkv3.actstudio.xyz/api/content/items/ProductInventory?populate=2', {
                    method: 'GET',
                })
                .then(res1=> res1.json())
                .then((relatedP)=> {

                    //get sizes for current product
                    this.productInfo = relatedP.filter(elem => elem.product._id === this.product._id);

                    //sizes for related products
                    if(this.product.relatedProduct1){
                        this.relatedProductSizes1 = relatedP.filter(elem => elem.product._id === this.product.relatedProduct1._id);
                    }

                    if(this.product.relatedProduct2){
                        this.relatedProductSizes2 = relatedP.filter(elem => elem.product._id === this.product.relatedProduct2._id);
                    }

                    if(this.product.relatedProduct3){
                        this.relatedProductSizes3 = relatedP.filter(elem => elem.product._id === this.product.relatedProduct3._id);
                    }
                })
                .then(()=> {
                   
                    //this.selectedSize = this.productInfo[0]
                    for(let index=0; index<this.productInfo.length; index++){
                        if(this.productInfo[index].inventory > 0){
                            this.selectedSize = this.productInfo[index];
                            break;
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            }else{
                this.showErrorPage = true;
            }

        })
        .catch((error) => {
            console.error('Error:', error);
        });
        
    },
    computed: {
        availableSizes: function(){
            return this.productInfo.filter(elem => elem.inventory >0);
        }
    },
    methods: {
        add: function() {
            if(parseInt(this.selectedSize.inventory) > this.quantity){
                this.quantity++;
            }
            
        },
        remove: function() {
            if( this.quantity > 1){
                this.quantity--;
            }
        },

        addToCart: function(){
            let cartItem = {
                _id: this.selectedSize._id,
                quantity: this.quantity,
                inventory: this.selectedSize.inventory,
                size: this.selectedSize.size,
                price: this.selectedSize.price,
                image: this.product.cover,
                name: this.product.name,
                subCategory: this.product.SubCategory.name
            };
            
            this.$root.$emit('addProductToCart', cartItem );
        }
    },
    beforeDestroy: function(){
        document.getElementById('footer').classList.remove('inProduct');
    },
    watch: {
        selectedSize: function() {
            this.quantity = 1;
        },
        $route: function(){
            fetch('https://rawkv3.actstudio.xyz/api/content/items/Productos?populate=2&filter={url:"'+this.$route.params.productUrl+'"}', {
                method: 'GET',
            })
            .then(res => res.json())
            .then(currentProduct => this.product = currentProduct[0]) 
            .then(() => {
                //fetch for the size and inventory info
                if(this.product){
                    fetch('https://rawkv3.actstudio.xyz/api/content/items/ProductInventory?populate=2', {
                        method: 'GET',
                    })
                    .then(res1=> res1.json())
                    .then((relatedP)=> {

                        //get sizes for current product
                        this.productInfo = relatedP.filter(elem => elem.product._id === this.product._id);

                        //sizes for related products
                        if(this.product.relatedProduct1){
                            this.relatedProductSizes1 = relatedP.filter(elem => elem.product._id === this.product.relatedProduct1._id);
                        }

                        if(this.product.relatedProduct2){
                            this.relatedProductSizes2 = relatedP.filter(elem => elem.product._id === this.product.relatedProduct2._id);
                        }

                        if(this.product.relatedProduct3){
                            this.relatedProductSizes3 = relatedP.filter(elem => elem.product._id === this.product.relatedProduct3._id);
                        }
                    })
                    .then(()=> {
                    
                        //this.selectedSize = this.productInfo[0]
                        for(let index=0; index<this.productInfo.length; index++){
                            if(this.productInfo[index].inventory > 0){
                                this.selectedSize = this.productInfo[index];
                                break;
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
                }else{
                    this.showErrorPage = true;
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    }
}
</script>
